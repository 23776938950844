:root {
  --nudge-bg: #fff;
  --nudge-text: #333;
  --nudge-dark-bg: #333;
  --nudge-second-text: #767676;
  --nudge-light-text: #fff;
  --nudge-border-color: #ccc;
  --nudge-active-state: #f0f0f0;
  --nudge-hover-state: #fafafa;
  --nudge-success-color: #52c41a;
  --nudge-danger-color: #faad14;
  --nudge-error-color: #ff4d4f;
  --nudge-info-color: #1890ff;
  --nudge-chat-incoming-bg: #ececec;
  --nudge-chat-outgoing-bg: #22a7f0;
  --nudge-incoming-sender-text-color: #b5b5b5;
  --nudge-outgoing-sender-text-color: #82aac8;
  --nudge-compose-box-height: 4rem; /* Desired initial height (flex-basis). */
  --nudge-search-box-height: 3rem; /* Desired initial height (flex-basis). */
  --ant-header-height: 64px; /* this is defined by antd */
}

body,
#root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

#nudge-root {
  color: var(--nudge-text);
  height: 100vh;
  width: 100%;
}

.nudge-banner-tab {
  display: flex;
  height: 100%;
  align-items: center;
}

.nudge-banner-tab .ant-typography {
  margin: 0 0 0 1rem;
}

.-sub-head {
  color: var(--nudge-second-text);
  font-weight: normal;
}

.-v-label {
  margin: 0 1rem;
  width: 12rem;
  display: inline-block;
  text-align: right;
  font-weight: bold;
}

.spin-container {
  width: 100%;
  text-align: center;
}

#nudge-header {
  background-color: var(--nudge-bg);
  border-bottom: 1px solid var(--nudge-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#nudge-header.-smol {
  padding: 0 10px;
}

#nudge-banner-logo {
  flex-grow: 0;
  flex-shrink: 0;
}

#nudge-nav {
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 100%;
  justify-content: right;
}

#nudge-settings {
  overflow: auto;
}

#nudge-chat-panel {
  background-color: var(--nudge-bg);
  overflow: hidden;
}

#nudge-chat-list-container {
  height: calc(100% - var(--nudge-search-box-height));
  overflow-y: auto;
}

#nudge-chat-filter-container {
  border-bottom: 1px solid var(--nudge-border-color);
  padding: 0.25rem 1rem;
}

#nudge-manage-ride {
  height: calc(
    100vh - var(--nudge-search-box-height) - var(--ant-header-height)
  );
  width: 100%;
  position: relative;
}

#nudge-manage-ride .-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem 2rem;
}

.nudge-body {
  border-left: 1px solid var(--nudge-border-color);
  background-color: var(--nudge-bg);
  height: 100%;
  display: flex;
  flex-direction: column;
}

#nudge-home-window {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#nudge-compose-box {
  border-top: 1px solid var(--nudge-border-color);
  flex-basis: var(--nudge-compose-box-height);
  flex-grow: 1;
}

#nudge-compose-box .ant-row {
  height: 100%;
}

.ant-input.chat-input {
  resize: none;
  height: inherit;
}

#nudge-chat {
  position: relative;
}

#nudge-chat-main {
  background-color: var(--nudge-bg);
  border-right: 1px solid var(--nudge-border-color);
}

#nudge-more-info {
  background-color: var(--nudge-bg);
  overflow-y: auto;
}

.nudge-more-info-inner {
  padding: 1rem;
}

#nudge-chat-window {
  padding-left: 4rem;
  padding-right: 4rem;
  overflow-x: visible;
  overflow-y: auto;
  flex-basis: calc(
    100% - var(--nudge-compose-box-height) - var(--nudge-search-box-height)
  );

  display: flex;
  flex-direction: column-reverse;
}

#nudge-chat-window.-smol {
  padding-left: 1rem;
  padding-right: 1rem;
}

#nudge-chat-info {
  flex-basis: var(--nudge-search-box-height);
  flex-shrink: 0;
  border-bottom: 1px solid var(--nudge-border-color);
  padding: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#nudge-chat-info > div {
  height: 100%;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#nudge-chat-info .transit-button {
  flex-basis: 20%;
}

#nudge-chat-info > div + div {
  border-left: 1px solid var(--nudge-border-color);
}

#nudge-chat-search-container {
  height: var(--nudge-search-box-height);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--nudge-border-color);
}

#nudge-chat-window .infinite-scroll-component {
  display: flex;
  flex-direction: column-reverse;
}

.nudge-chat-list-item.ant-list-item {
  padding: 1rem;
}

.nudge-chat-list-item.ant-list-item.-disabled h4 {
  color: var(--nudge-error-color);
}

.ant-input-search.nudge-chat-search {
  resize: none;
}

.ant-input-search.nudge-chat-search .ant-btn {
  border: 0;
}

.nudge-chat-list-item .ant-list-item-meta-description {
  /* Show only one line of text, with ellipses. */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nudge-chat-list-item.-active {
  background-color: var(--nudge-active-state);
}

.nudge-chat-list-item.-inactive {
  cursor: pointer;
}

.nudge-chat-list-item.-unread,
.nudge-chat-list-item.-unread .ant-list-item-meta-title {
  font-weight: bold;
}

.nudge-chat-list-item.-inactive:hover {
  background-color: var(--nudge-hover-state);
}

.nudge-chat-thread-timestamp {
  text-align: center;
  padding: 0.25rem 0;
  display: block;
}

.ant-list-item.nudge-chat-message-container {
  border-bottom: none;
  padding: 2px 0;
}

.nudge-chat-message-name {
  display: flex;
}

.nudge-chat-message-name.-incoming {
  color: var(--nudge-incoming-sender-text-color);
}

.nudge-chat-message-name.-outgoing {
  color: var(--nudge-outgoing-sender-text-color);
}

.nudge-chat-message-container.-incoming,
.nudge-chat-message-name.-incoming {
  flex-direction: row;
}

.nudge-chat-message-container.-outgoing,
.nudge-chat-message-name.-outgoing {
  flex-direction: row-reverse;
}

.nudge-chat-message-container.-selected .nudge-chat-message {
  border: 2px solid rgba(249, 154, 32, 0.93);
}

.nudge-chat-message-container.-translating {
  opacity: 0.5;
}

.nudge-chat-message {
  max-width: 75%;
  border-radius: 10px;
  padding: 0.25rem 0.75rem;
  white-space: pre-wrap;
  position: relative;
}

.nudge-chat-message .-translation,
.-translation {
  width: 100%;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px dashed var(--nudge-border-color);
  color: var(--nudge-info-color);
}

.nudge-chat-message .-translation-attr {
  float: right;
  padding-top: 0.2rem;
  padding-left: 0.25rem;
}

.nudge-chat-message .-translation-source {
  float: left;
  white-space: initial;
  padding-top: 0.2rem;
  padding-right: 0.25rem;
}

.nudge-chat-message .nudge-chat-message-translate {
  position: absolute;
  right: -0.5em;
  top: -0.5em;
}

.-incoming .nudge-chat-message {
  color: var(--nudge-text);
  background-color: var(--nudge-chat-incoming-bg);
}

.-outgoing .nudge-chat-message {
  color: var(--nudge-light-text);
  background-color: var(--nudge-chat-outgoing-bg);
}

.-void .nudge-chat-message {
  opacity: 0.6;
}

.nudge-chat-message-status {
  font-size: 0.5rem;
}

.-incoming .nudge-chat-message-status {
  display: none;
}

.-outgoing .nudge-chat-message-status {
  text-align: right;
}

.nudge-loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.-centered {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nudge-logo {
  width: 25%;
}

.nudge-audit-content {
  padding: 0 2rem;
}

.nudge-audit-message-item {
  cursor: pointer;
}

.nudge-audit-message-item.-click:hover {
  background-color: var(--nudge-hover-state);
}

#nudge-nonstandard-replies {
  overflow: auto;
}

#nudge-follow-up {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#nudge-follow-up-thread-container {
  overflow-x: visible;
  overflow-y: auto;
  flex-grow: 1;
}

.nudge-list-placeholder {
  padding: 0 2rem;
  width: 100%;
}

.ant-skeleton-button.nudge-list-placeholder {
  width: 100%;
}

#fsm-graph svg {
  border: 1px solid var(--nudge-border-color);
  border-radius: 6px;
}

#nudge-view-rides {
  width: 100%;
  overflow-y: scroll;
}

.-click {
  cursor: pointer;
}
